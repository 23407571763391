#navbar{
    background-color: #A14F01;
    padding-bottom: 40px;
    padding-top: 40px;
    display: flex;
    justify-content: space-between;
}

#logo{
    margin-left: 150px;
}

.menubuttonscontainer{
    margin-right: 200px;
}

.menubutton{
    margin-left: 10px;
    margin-right: 10px;
    padding: 5px 15px;
    border-radius: 50px;
}