#SelecteerTicketsBalk{
    display: flex;
    justify-content: space-around;
    height: fit-content;
    width: 55%;
    background-color: brown;
    margin: 0 auto;
    padding-top: 1px;
    border-radius: 25px;
    align-items: center;
    min-width: 730px;

    text-align: center;
    margin-top: 20px;
    color: white;
}

.BalkItem{
    display: flex;
    justify-content: space-between;
    margin: 15px;
    background-color: blue;
    height: fit-content;
    padding-left: 5px;
    width: 300px;
    border-radius: 25px;
}

#orderTickets{
    width: fit-content;
    justify-content: space-around;
    display: flex;
    margin: 10px auto;
}