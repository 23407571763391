#Weersverwachting{
    display: flex-box;
    height: fit-content;
    background-color: brown;
    width: 55%;
    margin: 0 auto;
    margin-top: 10px;
    border-radius: 25px;
    color: white;
}

#WeersverwachtingContent{
    display: flex-box;
    text-align: left;
    padding-left: 10%;
    padding-bottom: 5%;
}