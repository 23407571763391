/**
 * Demo Styles
 */

 html {
    height: 100%;
    box-sizing: border-box;
  }
  
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  
  body {
    margin: 0;
    font-family: "Helvetica Neue", Arial, sans-serif;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #F6A65B;
  }
  
  .demo {
    flex: 1;
    margin: 0 auto;
    width: 100%;
  }
  
  .demo h1 {
    margin-top: 0;
  }
  
  /**
   * Footer Styles
   */
  
  .footer {
    padding: 1rem;
    background-color: #efefef;
    text-align: center;
  }

  #root{
    margin: 0;
    font-family: "Helvetica Neue", Arial, sans-serif;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #F6A65B;
  }


/**
*   Slideshow Styles
*/
.slideshow_container{
    display: flex;
    justify-content: center;
  }
  
.slideshow{
    background-color: beige;
    height: 400px;
    width: 800px;
  }
  
  /**
  *   Image Button Styles
  */
  
  .imageButton{
    height: 150px;
    margin-left: 15%;
    margin-right: 15%;
  }
  
  .imageButton:hover{
    cursor: pointer;
  }
  
  .buttonSection{
    margin-top: 80px;
    margin-bottom: 80px;
    display: flex;
    justify-content: center;
  }